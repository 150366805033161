import { Box, List, ListItem, ListItemProps, ListItemText, SxProps, Typography } from "@mui/material";
import { ReactElement, ReactNode, useState } from "react";
import colors from "../../colors";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabToggleChip } from "@CabComponents/CabToggleChip";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { CabIcon } from "@CabComponents/CabIcon";
import { BsDot } from "react-icons/bs";
import { IoOpenOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

interface WidgetProps {
  header?: string;
  action?: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}

export const Widget = ({ header, action, children, sx }: WidgetProps): ReactElement => (
  <Box
    border={`1px solid ${colors.black400}`} borderRadius={2}
    padding={1} paddingTop={0} paddingBottom={0}
    width='100%' overflow="hidden" sx={sx}
  >
    <Box display="flex" justifyContent="space-between">
      {header && (
        <Typography variant="h2" fontSize={18} paddingTop={2} paddingLeft={1} paddingBottom={2}>
          {header}
        </Typography>
      )}
      {action}
    </Box>
    {children}
  </Box>
);

export const WidgetList = ({ children }: { children: ReactNode }) => (
  <List disablePadding sx={{ paddingLeft: 1, paddingRight: 1 }}>
    {children}
  </List>
);

export const WidgetListItem = ({ onClick, onBlur, secondaryAction, children }: {
  onClick?: () => void;
  onBlur?: () => void;
  secondaryAction?: ListItemProps['secondaryAction'];
  children: ReactNode;
}) => (
  <ListItem
    divider
    disableGutters
    secondaryAction={secondaryAction}
    onClick={onClick}
    onBlur={onBlur}
    sx={{ cursor: onClick ? 'pointer' : undefined }}
  >
    {children}
  </ListItem>
);

export const BasicInfoListItem = ({ 
  title, text, color, inputType, inputValue, selectOptions, onSave, onInputChange, placeholder, noOptionsText, onClick,
  isDirty, link
}: {
  title: string, text?: string | null, inputType: 'text'|'select'|'multiline'|'autocomplete', 
  inputValue: string | number | number[] | null,
  onSave: (val: string | number | number[] | null) => void, 
  color?: string; selectOptions?: { value: string|number, label: string, color?: string }[], 
  onInputChange?: (val: string) => void; placeholder?: string; noOptionsText?: string;
  onClick?: () => void;
  isDirty?: boolean;
  link?: string;
}) => {
  const [editing, setEditing] = useState(false);
  // const itemControl = useRef<null | ReactElement>(null);

  const handleItemClick = () => {
    if (!editing) {
      setEditing(true);
      // (itemControl.current as unknown as HTMLElement)?.focus();
    }
  };

  const handleItemBlur = () => {
    setEditing(false);
  };

  const handleEnter: React.KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      setEditing(false);
    }
  };

  return (
    <WidgetListItem onClick={handleItemClick} secondaryAction={
      isDirty ? <CabIcon Icon={BsDot} size="large" color={colors.black400} sx={{ marginTop: 3 }} />
        : (link && !editing && <Link to={link}><CabIcon Icon={IoOpenOutline} sx={{ marginTop: 3 }} /></Link>)
    }>
      <ListItemText
        primary={title}
        secondary={(
          <Box>
            <Box visibility={editing ? 'visible' : 'hidden'} height={editing ? undefined : 0}>
              {inputType === 'text' ? (
                <CabTextInput
                  // inputRef={ref}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  onKeyDown={handleEnter}
                  overrides={{ autoFocus: true }}
                  value={inputValue}
                  onChange={e => onSave(e.target.value)}
                  fullWidth
                />
              ) : inputType === 'multiline' ? (
                <CabTextInput
                  // inputRef={ref}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  onKeyDown={handleEnter}
                  overrides={{ autoFocus: true }}
                  value={inputValue}
                  onChange={e => onSave(e.target.value)}
                  fullWidth
                  multiline
                  minRows={2}
                  maxRows={2}
                />
              ) : inputType === 'autocomplete' ? (
                <CabAutocomplete<number, never>
                  overrides={{ autoFocus: true, onBlur: handleItemBlur }}
                  onKeyDown={handleEnter}
                  // inputRef={itemControl}
                  // onBlur={handleItemBlur}
                  options={selectOptions?.map(option => ({
                    value: Number(option.value),
                    label: option.label
                  })) || []}
                  noOptionsText={noOptionsText}
                  placeholder={placeholder}
                  onInputChange={onInputChange ? (v) => onInputChange(v) : undefined}
                  value={typeof inputValue !== 'string' ? inputValue : null}
                  onChange={onSave}
                  sx={{ width: '100%' }}
                />
              ) : (
                <CabDropdown
                  overrides={{
                    autoFocus: true,
                    onKeyDown: handleEnter,
                  }}
                  // inputRef={itemControl}
                  onBlur={handleItemBlur}
                  options={selectOptions?.map(option => ({
                    value: option.value,
                    label: option.color ? <CabToggleChip chipColor={option.color} label={option.label} selected />
                      : option.label
                  })) || []}
                  value={inputValue ?? ''}
                  onChange={e => onSave(e.target.value)}
                  sx={{ width: '100%' }}
                />
              )}
            </Box>
            {!editing && (
              <Box>
                {color
                  ? <CabToggleChip chipColor={color} label={text} selected />
                  : text || <Box sx={{ height: 30 }} component="span">&nbsp;</Box>
                }
              </Box>
            )}
          </Box>
        )}
        primaryTypographyProps={{ fontSize: 13, fontWeight: 'bolder', color: colors.black700 }}
        secondaryTypographyProps={editing
          ? { component: Box }
          : {
            fontWeight: 'bold', fontSize: 16, color: colors.black900, onClick,
            sx: { cursor: onClick ? 'pointer' : undefined }
          }
        }
      />
    </WidgetListItem>
  );
};